@import "../global/___variables"

footer
    border-top 3px solid colort
    border-bottom 4px solid colort
    .testemonials
        vertical-align: top
        background: #2e3131 url(/static/img/static-temp/bg-testimonial.jpg) no-repeat
        background-position: center center
        +below(768px)
            background #2e3131
        .testemonials-i
            lost-center 1140px
            height 100%
            padding 30px 60px
            +below(1250px)
                width 94%
            +below(560px)
                padding 30px 40px
            +below(400px)
                padding 20px 25px
            .testemonial
                text-align center
                .fas
                    font-size 28px
                    color colors
                    margin-bottom 7px
                .testemonial-title
                    color white
                    text-transform uppercase
                    font-weight bold
                    font-size 17px
                    margin-bottom 15px
                .testemonial-content
                .testemonial-content a
                    color white - 23%
                    font-weight lighter
                    font-size 16px
                    line-height 24px
                    +below(1020px)
                        font-size 14px
                .testemonial-content
                    height 90px
                    +below(840px)
                        height 130px
                    +below(768px)
                        height 150px
                    +below(680px)
                        height 180px
                    +below(560px)
                        height 220px
                    +below(450px)
                        height 290px
                    +below(400px)
                        height 340px
                .testemonial-name
                    color colort
                    font-style: italic
                    font-size 16px
            .slick-next, .slick-prev
                top 50%
                background none
                &:before
                    color white
            +below(770px)
                .slick-prev
                    left -13px
                .slick-next
                    right -13px

    .footer
        background colorq
        .footer-i
            display flex
            height 250px
            +below(1020px)
                flex-direction: column
                height auto
            .links, .sacs, .newsletter
                flex-direction: column
                +below(1100px)
                    padding 0 15px
                .link, .sac p
                    color white
                    font-size 14px
                    font-weight lighter
                    margin 0
                    +below(1100px)
                        font-size 13px
            .links-title, .sacs-title, .newsletter-title
                color white
                margin-bottom 20px
                padding-top 25px
                +below(1020px)
                    padding-top 0
                +below(1100px)
                    margin-bottom 5px
            .links-w, .sacs-w, .newsletter-w
                display flex
                flex-direction: column
                justify-content space-between
                height 140px
            .links-sacs
                display flex
                align-items: center
                width 65.4%
                +below(1020px)
                    width 100%
                    padding 30px 0
                +below(560px)
                    display block
                    padding 0
                .logo, .links, .sacs
                    display flex
                    height 100%
                    padding-right 20px
                .logo
                    align-items: center
                    justify-content flex-start
                    width 29%
                    +below(770px)
                        display none
                .links
                    width 29%
                    +below(770px)
                        width 50%
                        padding-left 50px
                    +below(670px)
                        padding-left 30px
                    +below(560px)
                        width 100%
                        text-align center
                        background #1B2942
                        padding 20px 0
                        .links-w
                            align-items: center
                .sacs
                    width 42%
                    +below(770px)
                        width 50%
                        padding-left 50px
                    +below(670px)
                        padding-left 30px
                    +below(560px)
                        width 100%
                        text-align center
                        padding 20px 0
                        .sacs-w
                            align-items: center
                    .fas, .sac-txt
                        float left
                    .fas
                        color colors
                        margin-right 8px
                        line-height 21px
            .social-newsletter
                display flex
                width 34.6%
                +below(1020px)
                    width 100%
                    background #1B2942
                +below(700px)
                    display block
                .social
                    display flex
                    flex-direction: column
                    align-items: center
                    justify-content space-between
                    width 24%
                    height 100%
                    padding 30px 0
                    +below(1020px)
                        width 50%
                        flex-direction: row
                        height auto
                        justify-content center
                        .social-icon
                            margin 0 10px
                    +below(700px)
                        width 100%
                        padding 20px 0
                    .social-icon
                        display inline-flex
                        align-items center
                        justify-content center
                        background colors
                        width 34px
                        height 34px
                        .fab
                            color white
                            font-size 18px
                .newsletter
                    width 76%
                    padding-left 20px
                    +below(1100px)
                        width 75%
                    +below(1020px)
                        width 50%
                        padding 20px 30px
                    +below(700px)
                        width 100%
                        background #283958
                    .newsletter-title
                        .fas
                            color colors
                    .newsletter-w
                        .newsletter-footer
                            display flex
                            justify-content space-between
                            align-items: flex-end
                            height 40px
                            .newsletter-footer-txt
                                width 70%
                                margin 0
                                color white
                                font-weight lighter
                                padding-right 5px
                                font-size 13px
                                line-height 14px
                                +below(1100px)
                                    font-size 13px
                            .btn
                                font-weight bold

    .copyright-w
        .copyright-content
            display flex
            justify-content space-between
            padding 20px 0
            +below(800px)
                display block
                padding 10px 0
            .copyright-title
                margin 0
                font-size 10px
                font-weight lighter
                color texts2 + 30%
            +below(800px)
                text-align center
            .img-w
                height 55px
                padding 10px 0
                +below(440px)
                    height 40px
                img
                    height 100%
                    margin-right 10px
                    +below(600px)
                        height 80%
                    +below(440px)
                        height 100%
                    +below(360px)
                        height 90%
            .payment-methods, .security
                +below(800px)
                    padding 10px 0 5px
                    +below(440px)
                        padding 0

        .copyright
            border-top 1px solid cinza - 15%
            font-size 13px
            color texts2 + 30%
            font-weight lighter
            margin 0
            text-align center
            padding 20px 0
