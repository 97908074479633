footer {
  border-top: 3px solid #23a7e2;
  border-bottom: 4px solid #23a7e2;
}
footer .testemonials {
  vertical-align: top;
  background: #2e3131 url("/static/img/static-temp/bg-testimonial.jpg") no-repeat;
  background-position: center center;
}
@media only screen and (max-width: 768px) {
  footer .testemonials {
    background: #2e3131;
  }
}
footer .testemonials .testemonials-i {
  lost-center: 1140px;
  height: 100%;
  padding: 30px 60px;
}
@media only screen and (max-width: 1250px) {
  footer .testemonials .testemonials-i {
    width: 94%;
  }
}
@media only screen and (max-width: 560px) {
  footer .testemonials .testemonials-i {
    padding: 30px 40px;
  }
}
@media only screen and (max-width: 400px) {
  footer .testemonials .testemonials-i {
    padding: 20px 25px;
  }
}
footer .testemonials .testemonials-i .testemonial {
  text-align: center;
}
footer .testemonials .testemonials-i .testemonial .fas {
  font-size: 28px;
  color: #8ba3cf;
  margin-bottom: 7px;
}
footer .testemonials .testemonials-i .testemonial .testemonial-title {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 15px;
}
footer .testemonials .testemonials-i .testemonial .testemonial-content,
footer .testemonials .testemonials-i .testemonial .testemonial-content a {
  color: #c4c4c4;
  font-weight: lighter;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 1020px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content,
  footer .testemonials .testemonials-i .testemonial .testemonial-content a {
    font-size: 14px;
  }
}
footer .testemonials .testemonials-i .testemonial .testemonial-content {
  height: 90px;
}
@media only screen and (max-width: 840px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 130px;
  }
}
@media only screen and (max-width: 768px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 150px;
  }
}
@media only screen and (max-width: 680px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 180px;
  }
}
@media only screen and (max-width: 560px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 220px;
  }
}
@media only screen and (max-width: 450px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 290px;
  }
}
@media only screen and (max-width: 400px) {
  footer .testemonials .testemonials-i .testemonial .testemonial-content {
    height: 340px;
  }
}
footer .testemonials .testemonials-i .testemonial .testemonial-name {
  color: #23a7e2;
  font-style: italic;
  font-size: 16px;
}
footer .testemonials .testemonials-i .slick-next,
footer .testemonials .testemonials-i .slick-prev {
  top: 50%;
  background: none;
}
footer .testemonials .testemonials-i .slick-next:before,
footer .testemonials .testemonials-i .slick-prev:before {
  color: #fff;
}
@media only screen and (max-width: 770px) {
  footer .testemonials .testemonials-i .slick-prev {
    left: -13px;
  }
  footer .testemonials .testemonials-i .slick-next {
    right: -13px;
  }
}
footer .footer {
  background: #002656;
}
footer .footer .footer-i {
  display: flex;
  height: 250px;
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i {
    flex-direction: column;
    height: auto;
  }
}
footer .footer .footer-i .links,
footer .footer .footer-i .sacs,
footer .footer .footer-i .newsletter {
  flex-direction: column;
}
@media only screen and (max-width: 1100px) {
  footer .footer .footer-i .links,
  footer .footer .footer-i .sacs,
  footer .footer .footer-i .newsletter {
    padding: 0 15px;
  }
}
footer .footer .footer-i .links .link,
footer .footer .footer-i .sacs .link,
footer .footer .footer-i .newsletter .link,
footer .footer .footer-i .links .sac p,
footer .footer .footer-i .sacs .sac p,
footer .footer .footer-i .newsletter .sac p {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  margin: 0;
}
@media only screen and (max-width: 1100px) {
  footer .footer .footer-i .links .link,
  footer .footer .footer-i .sacs .link,
  footer .footer .footer-i .newsletter .link,
  footer .footer .footer-i .links .sac p,
  footer .footer .footer-i .sacs .sac p,
  footer .footer .footer-i .newsletter .sac p {
    font-size: 13px;
  }
}
footer .footer .footer-i .links-title,
footer .footer .footer-i .sacs-title,
footer .footer .footer-i .newsletter-title {
  color: #fff;
  margin-bottom: 20px;
  padding-top: 25px;
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i .links-title,
  footer .footer .footer-i .sacs-title,
  footer .footer .footer-i .newsletter-title {
    padding-top: 0;
  }
}
@media only screen and (max-width: 1100px) {
  footer .footer .footer-i .links-title,
  footer .footer .footer-i .sacs-title,
  footer .footer .footer-i .newsletter-title {
    margin-bottom: 5px;
  }
}
footer .footer .footer-i .links-w,
footer .footer .footer-i .sacs-w,
footer .footer .footer-i .newsletter-w {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
}
footer .footer .footer-i .links-sacs {
  display: flex;
  align-items: center;
  width: 65.4%;
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i .links-sacs {
    width: 100%;
    padding: 30px 0;
  }
}
@media only screen and (max-width: 560px) {
  footer .footer .footer-i .links-sacs {
    display: block;
    padding: 0;
  }
}
footer .footer .footer-i .links-sacs .logo,
footer .footer .footer-i .links-sacs .links,
footer .footer .footer-i .links-sacs .sacs {
  display: flex;
  height: 100%;
  padding-right: 20px;
}
footer .footer .footer-i .links-sacs .logo {
  align-items: center;
  justify-content: flex-start;
  width: 29%;
}
@media only screen and (max-width: 770px) {
  footer .footer .footer-i .links-sacs .logo {
    display: none;
  }
}
footer .footer .footer-i .links-sacs .links {
  width: 29%;
}
@media only screen and (max-width: 770px) {
  footer .footer .footer-i .links-sacs .links {
    width: 50%;
    padding-left: 50px;
  }
}
@media only screen and (max-width: 670px) {
  footer .footer .footer-i .links-sacs .links {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 560px) {
  footer .footer .footer-i .links-sacs .links {
    width: 100%;
    text-align: center;
    background: #1b2942;
    padding: 20px 0;
  }
  footer .footer .footer-i .links-sacs .links .links-w {
    align-items: center;
  }
}
footer .footer .footer-i .links-sacs .sacs {
  width: 42%;
}
@media only screen and (max-width: 770px) {
  footer .footer .footer-i .links-sacs .sacs {
    width: 50%;
    padding-left: 50px;
  }
}
@media only screen and (max-width: 670px) {
  footer .footer .footer-i .links-sacs .sacs {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 560px) {
  footer .footer .footer-i .links-sacs .sacs {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  footer .footer .footer-i .links-sacs .sacs .sacs-w {
    align-items: center;
  }
}
footer .footer .footer-i .links-sacs .sacs .fas,
footer .footer .footer-i .links-sacs .sacs .sac-txt {
  float: left;
}
footer .footer .footer-i .links-sacs .sacs .fas {
  color: #8ba3cf;
  margin-right: 8px;
  line-height: 21px;
}
footer .footer .footer-i .social-newsletter {
  display: flex;
  width: 34.6%;
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i .social-newsletter {
    width: 100%;
    background: #1b2942;
  }
}
@media only screen and (max-width: 700px) {
  footer .footer .footer-i .social-newsletter {
    display: block;
  }
}
footer .footer .footer-i .social-newsletter .social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 24%;
  height: 100%;
  padding: 30px 0;
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i .social-newsletter .social {
    width: 50%;
    flex-direction: row;
    height: auto;
    justify-content: center;
  }
  footer .footer .footer-i .social-newsletter .social .social-icon {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 700px) {
  footer .footer .footer-i .social-newsletter .social {
    width: 100%;
    padding: 20px 0;
  }
}
footer .footer .footer-i .social-newsletter .social .social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #8ba3cf;
  width: 34px;
  height: 34px;
}
footer .footer .footer-i .social-newsletter .social .social-icon .fab {
  color: #fff;
  font-size: 18px;
}
footer .footer .footer-i .social-newsletter .newsletter {
  width: 76%;
  padding-left: 20px;
}
@media only screen and (max-width: 1100px) {
  footer .footer .footer-i .social-newsletter .newsletter {
    width: 75%;
  }
}
@media only screen and (max-width: 1020px) {
  footer .footer .footer-i .social-newsletter .newsletter {
    width: 50%;
    padding: 20px 30px;
  }
}
@media only screen and (max-width: 700px) {
  footer .footer .footer-i .social-newsletter .newsletter {
    width: 100%;
    background: #283958;
  }
}
footer .footer .footer-i .social-newsletter .newsletter .newsletter-title .fas {
  color: #8ba3cf;
}
footer .footer .footer-i .social-newsletter .newsletter .newsletter-w .newsletter-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40px;
}
footer .footer .footer-i .social-newsletter .newsletter .newsletter-w .newsletter-footer .newsletter-footer-txt {
  width: 70%;
  margin: 0;
  color: #fff;
  font-weight: lighter;
  padding-right: 5px;
  font-size: 13px;
  line-height: 14px;
}
@media only screen and (max-width: 1100px) {
  footer .footer .footer-i .social-newsletter .newsletter .newsletter-w .newsletter-footer .newsletter-footer-txt {
    font-size: 13px;
  }
}
footer .footer .footer-i .social-newsletter .newsletter .newsletter-w .newsletter-footer .btn {
  font-weight: bold;
}
footer .copyright-w .copyright-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
@media only screen and (max-width: 800px) {
  footer .copyright-w .copyright-content {
    display: block;
    padding: 10px 0;
  }
}
footer .copyright-w .copyright-content .copyright-title {
  margin: 0;
  font-size: 10px;
  font-weight: lighter;
  color: #949494;
}
@media only screen and (max-width: 800px) {
  footer .copyright-w .copyright-content {
    text-align: center;
  }
}
footer .copyright-w .copyright-content .img-w {
  height: 55px;
  padding: 10px 0;
}
@media only screen and (max-width: 440px) {
  footer .copyright-w .copyright-content .img-w {
    height: 40px;
  }
}
footer .copyright-w .copyright-content .img-w img {
  height: 100%;
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  footer .copyright-w .copyright-content .img-w img {
    height: 80%;
  }
}
@media only screen and (max-width: 440px) {
  footer .copyright-w .copyright-content .img-w img {
    height: 100%;
  }
}
@media only screen and (max-width: 360px) {
  footer .copyright-w .copyright-content .img-w img {
    height: 90%;
  }
}
@media only screen and (max-width: 800px) {
  footer .copyright-w .copyright-content .payment-methods,
  footer .copyright-w .copyright-content .security {
    padding: 10px 0 5px;
  }
}
@media only screen and (max-width: 800px) and (max-width: 440px) {
  footer .copyright-w .copyright-content .payment-methods,
  footer .copyright-w .copyright-content .security {
    padding: 0;
  }
}
footer .copyright-w .copyright {
  border-top: 1px solid #cfcfcf;
  font-size: 13px;
  color: #949494;
  font-weight: lighter;
  margin: 0;
  text-align: center;
  padding: 20px 0;
}
